import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TagList from "../components/tag_list"

export default function Template({ data }) {
  const app = data.allPrismicApp.edges[0].node.data
  return (
    <Layout>
      <SEO title={app.title} />
      <div class="blogPost pure-u-1-1">
        <h2 class="title uppercut">{app.name.text}</h2>

        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: app.content.html }} />

        <TagList tags={app.tags.map(t => t.tag)} />

        <br /><br />

        {app.images.map(({ image }, i) => <Img key={i} fixed={image.fixed} />)}

        <br /><br />

        <Link to="/apps">See more apps</Link>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($uid: String) {
    allPrismicApp(filter: {uid: {eq: $uid} }) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            content {
              html
            }
            tags {
              tag
            }
            images {
              image {
                fixed(width:300){
                  src
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`